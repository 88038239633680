import "./SobreMim.css";

const SobreMim = () => {
  return (
    <div className="sobre-mim">
      <div className="sobre-mim-principal">
        <div className="textos-sobre">
          <div className="sobre-mim-texto">
            <div className="sobre-mim-topo">
              <h1>Sobre mim</h1>
              <p>Olá! Sou a Jacqueline Guimarães, psicóloga clínica.</p>
            </div>
            <p>
              Graduada pelo UniCEUB e inscrita no Conselho Regional de
              Psicologia CRP - 01/25708.
            </p>
            <p>
              Adoto os princípios éticos e técnicos da Psicologia Humanista em
              minha prática clínica, sendo especialista na Abordagem Centrada na
              Pessoa (ACP) pela UNEPOS.
            </p>
            <p>
              Proporciono um ambiente ético, confidencial e acolhedor a fim de
              que meus Clientes possam explorar suas emoções e pensamentos de
              forma livre e confidencial.
            </p>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/5561992081099"
            >
              AGENDAR
            </a>
          </div>
        </div>
        <div className="div-foto-jac">
          <div className="foto-jac"></div>
        </div>

        <div className="foto-sobre-mbl"></div>
      </div>
    </div>
  );
};

export default SobreMim;
